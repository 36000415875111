.header {

    /* position */
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    z-index: 20;

    /* layout */
    display: flex;

    /* font */
    font-family: Be Vietnam Pro;
    font-size: max(calc(12px + 1.25vw), min(calc(1rem + 1.25vw), calc(20px + 1.25vw)));

    /* padding */
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 0.50em;
    padding-right: 0.50em;

    /* background */
    background-color: var(--primary-color);
}

.nav {

    /* layout */
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;

    /* background */
    background-color: inherit;

}

.header_list {

    /* layout */
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.header_list_item {

    /* layout */
    display: flex;
}

.header_link {

    /* mouse */
    cursor: pointer;

    /* font */
    font-family: inherit;
    font-size: 0.50em;
    font-weight: 300;
    color: var(--on-primary-color);

    /* padding */
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;

    /* layout */
    align-self: center;
}

.header_link:focus-visible {

    /* border */
    border-radius: 4px;

    /* font */
    color: var(--primary-color);

    /* background */
    background-color: var(--on-primary-color);

}

.header_link_active {

    /* mouse */
    cursor: pointer;

    /* font */
    font-family: inherit;
    font-size: 0.50em;
    font-weight: 500;
    color: var(--on-primary-color);

    /* padding */
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;

    /* border */
    border-bottom-color: var(--on-primary-color);
    border-bottom-width: 2px;
    border-bottom-style: solid;

    /* layout */
    align-self: center;
}

.header_link_active:focus-visible {

    /* border */
    border-radius: 4px;

    /* font */
    color: var(--primary-color);

    /* background */
    background-color: var(--on-primary-color);

}

/* mobile */
.mobile_header {

    /* layout */
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;

}

.menu_icon {
    
    /* mouse */
    cursor: pointer;

    /* layout */
    display: none;
    height: 2em;
    width: 2em;

    /* icon */
    fill: var(--on-primary-color);
    
}

/* Tablet */
@media only screen and (max-width: 768px) {

    .nav {

        /* layout */
        flex-direction: column;
        gap: 8px;

    }

    .menu_icon {

        /* layout */
        display: block;

    }

    .header_list {

        /* layout */
        display: none;
        flex-direction: column;
        gap: 8px;

    }

    .header_link,
    .header_link_active{
        font-size: 0.75em;
    }

}

.header_list_open {

    /* layout */
    display: flex;
}