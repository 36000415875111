.container {

    /* layout */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25em;

    /* background */
    background-color: var(--background);

    /* padding */
    padding-top: 15vh;

    /* font */
    font-size: max(calc(12px + 1.25vw), min(calc(1rem + 1.25vw), calc(20px + 1.25vw)));
}

.username {

    /* font */
    font-size: 1.25em;
    font-weight: 600;
    color: var(--primary-color);
}

.role {
    
    /* font */
    font-size: 0.60em;
    font-weight: 300;
    letter-spacing: 0.25em;
    color: var(--secondary-color);
}

.button {
    
    /* margin */
    margin-top: 20vh;
    
    /* font */
    font-size: 0.60em;
    
}