/* Load font */
@font-face {
  font-family: 'Be Vietnam Pro';
  src: url(./assets/fonts/BeVietnamPro/BeVietnamPro-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url(./assets/fonts/BeVietnamPro/BeVietnamPro-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url(./assets/fonts/BeVietnamPro/BeVietnamPro-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url(./assets/fonts/BeVietnamPro/BeVietnamPro-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}


* {

  /* colors */
  /* Accent colors */
  --primary-color: hsl(200, 100%, 15%);
  --on-primary-color: hsl(200, 100%, 85%);

  --secondary-color: hsl(30, 100%, 15%);
  --on-secondary-color: hsl(30, 100%, 50%);

  /* Neutral colors */
  /* background: pages background */
  --background: hsl(0, 0%, 100%);
  --on-background: hsl(0, 0%, 10%);
  /* surface: components background */
  --surface: hsl(0, 0%, 95%);
  --on-surface: hsl(0, 0%, 20%);

  --modal: hsla(0, 0%, 100%, 50%);

  --error: hsl(0, 100%, 50%);


}

/* reset css */
a {
  all: unset;
  text-decoration: none;
}

body {
  all: unset;
  margin: 0;
}

button {
  all: unset;
}

ul {
  all: unset;
  list-style: none;
}

li {
  /* all:unset; */
  list-style: inherit;
}

h1 {
  all: unset;
}

h2 {
  all: unset;
}

#root {
  display: flex;
  flex-direction: column;

  height: 100vh;
}