/* TODO find way to propagate the changes to background-color and color */
.button {

    /* mouse */
    cursor: pointer;

    /* border */
    border: none;
    border-radius: 4px;

    /* background */
    background-color: var(--primary-color);
    background-position: center;

    /* layout */
    vertical-align: middle;
    text-align: center;

    /* text */
    font-family: Be Vietnam Pro;
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--on-primary-color);

    /* animation */
    transition-property: background;
    transition-duration: 750ms;

    /* padding */
    padding-top: 0.50em;
    padding-bottom: 0.50em;
    padding-left: 0.75em;
    padding-right: 0.75em;
}

.button:hover {

    /* background */
    background: var(--primary-color) radial-gradient(circle, transparent 0.001%, var(--primary-color) 0.001%) center/15000000%;

}

.button:active {

    /* background */
    background-color: var(--on-primary-color);
    background-size: 100%;

    /* animation */
    transition-property: background;
    transition-duration: 0ms;

}

.button:focus-visible {

    /* background */
    background-color: var(--on-primary-color);

    /* font */
    color: var(--primary-color);

}

.button:focus-visible:hover{

    /* background */
    background: var(--on-primary-color) radial-gradient(circle, transparent 0.001%, var(--on-primary-color) 0.001%) center/15000000%;
}

.button:focus-visible:active{

    /* background */
    background-color: var(--primary-color);
    background-size: 100%;

    /* animation */
    transition-property: background;
    transition-duration: 0ms;

}




