.card {

    /* layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    /* font */
    font-size: max(calc(12px + 1.25vw), min(calc(1rem + 1.25vw), calc(20px + 1.25vw)));
    color: var(--on-primary-color);

    overflow: hidden;

    /* border */
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--primary-color);


    /* mouse */
    cursor: pointer;
}

.image {
 
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;

}

.card_header {

    /* layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    gap: 0.5em;


    
    width: 100%;
    box-sizing: border-box;

    /* background */
    background-color: var(--primary-color);

    /* padding */
    padding-top: 0.50em;
    padding-bottom: 0.50em;
    padding-left: 0.75em;
    padding-right: 0.75em;

}

.name {

    /* font */
    font-family: inherit;
    font-size: 0.60em;
    font-weight: 400;
    color: inherit;

}

.tags {

    /* display */
    display: flex;
    flex-direction: row;

    gap: 0.25em;
}

.tag {

    /* display */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* font */
    font-family: inherit;
    font-size: 0.35em;
    font-weight: 300;
    color: var(--primary-color);
        

    /* padding */
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 0.5em;
    padding-right: 0.5em;

    /* border */
    border-radius: 8px;
    
    /* background */
    background-color: var(--on-primary-color);
}