.link {

    /* mouse */
    cursor: pointer;

    /* decoration */
    text-decoration: underline;

    /* font */
    font-family: Be Vietnam Pro;
    font-size: 1rem;
    font-weight: 400;
    color: var(--on-primary-color);
}

.link:focus-visible {

    color: var(--primary-color);
    background-color: var(--on-primary-color);

    border-radius: 4px;
}