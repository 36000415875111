.container {

    /* layout */
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    /* border */
    border-color: var(--secondary-color);
    border-left-width: 0.125em;
    border-left-style: solid;

    /* padding */
    padding-left: 1.25em;
    padding-top: 1em;
    padding-bottom: 1em;

    /* font */
    font-family: Be Vietnam Pro;
    font-size: max(calc(12px + 1.25vw), min(calc(1rem + 1.25vw), calc(20px + 1.25vw)));
    color: var(--primary-color);

}

/* arrow up */
.container:first-child::before {

    content: "";

    /* position */
    position: relative;
    top: -1em;
    left: -1.625em;
    transform: rotate(-45deg);

    /* layout */
    width: 0.5em;
    height: 0.5em;

    /* border */
    border-top-width: 0.125em;
    border-top-color: inherit;
    border-top-style: solid;
    border-right-width: 0.125em;
    border-right-color: inherit;
    border-right-style: solid;

}

.header {

    /* position */
    position: relative;
    
    /* layout */
    display: inline-block;
    
}

.header_title {

    /* font */
    font-family: inherit;
    font-size: 0.80em;
    font-weight: 400;
    color: inherit;
}

.header::before {

    content: "";

    /* position */
    position: absolute;
    top: 50%;
    left: -1.6875em;
    transform: translateY(-50%);

    /* border */
    border-radius: 50%;
    
    /* layout */
    height: 0.75em;
    width: 0.75em;

    /* background */
    background-color: var(--on-secondary-color);

}

.header_date {

    /* font */
    font-family: inherit;
    font-size: 0.80em;
    font-weight: 400;
    color: inherit;
}

.header_date::before{
    content: " - ";
}

.list {

    /* layout */
    display: flex;
    flex-direction: column;
    
    /* list */
    list-style-position: outside;
    list-style-type: disc;

    /* margin */
    margin-left: 0.75em;

    /* font */
    color: inherit;

}

.list_item {

    /* font */
    font-family: inherit;
    font-size: 0.60em;
    font-weight: 300;
    color: inherit;

}

.content_text {

    /* font */
    font-family: inherit;
    font-size: 0.60em;
    font-weight: 300;
    color: inherit;
}

.link{

    /* font */
    font-family: inherit;
    font-size: 1em;
    font-weight: 300;
    color: inherit;
}