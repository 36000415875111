.modal{

    /* position */
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;

    /* layout */
    display: flex;
    justify-content: center;
    align-items: center;

    /* background */
    background-color: var(--modal);
}