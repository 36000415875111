.main {

    /* layout */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* background */
    background-color: var(--primary-container-color);

    /* margin */
    margin-top: calc(2.5vh + 10px + 0.50em + 2px);

    /* padding */
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    
}

.list {

    display: flex;
    flex-direction: column;

    margin-top: 25vh;
    margin-bottom: 15vh;
    max-width: 60vw;
}

/* Tablet */
@media only screen and (max-width: 900px) {

    .list {
        max-width: unset;
    }

}