.main{

    /* layout */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    /* margin */
    margin-top: calc(2.5vh + 10px + 0.50em + 2px);
    
    /* padding */
    padding-top: 15vh;
    padding-bottom: 5vh;
    padding-left: 2.5vw;
    padding-right: 2.5vw;

    /* font */
    font-size: calc(1rem + 1vw);
    color: var(--primary-color);
    
}

.page_not_found{

    /* font */
    font-size: 1em;
    font-weight: 600;
    color: inherit
}

.instructions{
    /* font */
    font-size: 0.75em;
    font-weight: 400;
    color: inherit
}