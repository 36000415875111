.main {

    /* layout */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    /* background */
    background-color: var(--primary-container-color);

    /* padding */
    padding-top: 20vh;

    /* font */
    font-family: Be Vietnam Pro;
    font-size: max(calc(12px + 1.25vw), min(calc(1rem + 1.25vw), calc(20px + 1.25vw)));

}

.all_projects_list {

    /* margin */
    margin-top: 15vh;

    /* layout */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90vw;
    gap: 8vw;
    justify-content: center;

}

.all_projects_list_item {    

    width: max(calc(256px + 8vw), calc(224px + 16vw));
    height: max(calc(144px + 4.5vw), calc(126px + 9vw));

}