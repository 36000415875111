.main {

    /* layout */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* background */
    background-color: var(--background);

    /* padding */
    padding-top: 1.25vh;
    padding-bottom: 1.25vh;
    padding-left: 2.5vw;
    padding-right: 2.5vw;

}

.list {

    /* layout */
    display: flex;
    flex-direction: column;
    gap: 64px;

    /* margin */
    margin-top: 25vh;
    margin-bottom: 15vh;
}