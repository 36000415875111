.card {

    /* position */
    position: relative;

    /* layout */
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    width: max(calc(128px + 24vw), calc(96px + 40vw));
    height: max(calc(72px + 13.5vw), calc(54px + 20.5vw));
    
    /* border */
    border-width: 1px;
    border-style: solid;
    border-color: var(--primary-color);
    border-radius: 8px;

    background-color: var(--background);

    /* font */
    font-size: max(calc(12px + 1.25vw), min(calc(1rem + 1.25vw), calc(20px + 1.25vw)));

    /* padding */
    padding: calc(1em + 8px);

}

.close_icon {

    /* position */
    position: absolute;
    top: 8px;
    right: 8px;

    /* svg */
    fill: var(--error);

    /* layout */
    height: 0.80em;
    width: 0.80em;

    /* mouse */
    cursor: pointer;

}

.header {

    display: flex;
    flex-direction: row;

}

.header_image {

    width: 3em;
    height: 3em;
    object-fit: contain;
}

.name {

    /* fonts */
    font-size: 0.60em;
    /* font-weight: 500; */
    color: var(--primary-color);

}

.description {

    flex-grow: 2;
    /* fonts */
    font-size: 0.40em;
    /* font-weight: 500; */
    color: var(--primary-color);

}

.header_info {

    /* layout */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;

}

.header_tags {

    /* display */
    display: flex;
    flex-direction: row;
    gap: 0.25em;
}

.header_tag {

    /* display */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* font */
    font-family: Be Vietnam Pro;
    font-size: 0.35em;
    font-weight: 300;
    color: var(--primary-color);
    
    /* padding */
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 0.5em;
    padding-right: 0.5em;

    /* border */
    border-radius: 8px;
    
    /* background */
    background-color: var(--on-primary-color);
}

.carousel{

    /* layout */
    width: 60%;
    align-self: center;
}

.list_image {
    width: 100%;
}

.links {

    display: flex;
    flex-direction: row;
    
    justify-content: space-around;
}

.link{
    
    /* fonts */
    font-size: 0.40em;
}