.card{
    
    display: flex;
    flex-direction: column;

    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--surface);

    box-shadow: 0px 0px 10px var(--surface);

    width: max(calc(256px + 8vw), calc(224px + 16vw));
    height: max(calc(144px + 4.5vw), calc(126px + 9vw));
    
    margin: 12px;

    align-items: center;
    justify-content: space-evenly;

    background-color: var(--background);
}

.text{
    font-size: max(calc(12px + 0.6vw), min(calc(1rem + 0.6vw), calc(20px + 0.6vw)));
}

.spinner {

    border-width: 4px;
    border-color: var(--on-primary-color);
    border-style: solid;

    border-top-color: var(--primary-color);
        
    border-radius: 50%;
    
    width: 64px;
    height: 64px;

    animation-name: spin;
    animation-duration: 0.85s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}