.container {

    /* layout */
    display: grid;
    grid-template-areas:
        "image company date"
        "none list none2";
    grid-template-rows: 2.50em 1.5fr;
    grid-template-columns: 2.50em 1.25fr auto; 
    gap: 0.50em;

    /* background */
    background-color: var(--surface);

    /* border */
    border-radius: 8px;

    /* padding */
    padding: 0.50em;
    padding-bottom: 1.25em;

    /* font */
    font-family: Be Vietnam Pro;
    font-size: max(calc(12px + 1.25vw), min(calc(1rem + 1.25vw), calc(20px + 1.25vw)));
    color: var(--primary-color);

    /* svg */
    fill: var(--primary-color);
}

.card_header {

    /* layout */
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 32px
}

.image {
    
    /* layout */
    grid-area: image;
    max-height: 2.50em;
    width: 2.50em;

    /* margin */
    margin: auto;

    /* border */
    border-radius: 12.5%;

    /* svg */
    fill: inherit;
}

.company {

    /* layout */
    grid-area: company;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.50em;
}

.company_name {

    /* layout */
    grid-area: company;

    /* font */
    font-size: 0.60em;
    font-family: inherit;
    font-weight: 400;
    color: inherit;
}

.position_title {

    /* layout */
    grid-area: position;

    /* font */
    font-size: 0.50em;
    font-family: inherit;
    font-weight: 300;
    color: inherit;
}

.date {

    /* layout */
    grid-area: date;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    gap: 0.50em;

}

.date_text {

    /* font */
    font-size: 0.45em;
    font-family: inherit;
    font-weight: 300;
    color: inherit
}

.list {

    /* layout */
    grid-area: list;
    display: flex;
    flex-direction: column;
    gap: 0.50em;

    /* list */
    list-style-type: disc;
    list-style-position: inside;

}

.list_item {

    /* font */
    font-size: 0.40em;
}

.list_item_text {

    /* font */
    font-size: 1em;
    font-family: inherit;
    font-weight: 300;
    color: inherit;

}