.main {

    /* layout */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;

    /* background */
    background-color: var(--background);

    /* padding */
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    /* font */
    font-size: max(calc(12px + 1.25vw), min(calc(1rem + 1.25vw), calc(20px + 1.25vw)));
}

.greetings {

    /* font */
    font-family: Be Vietnam Pro;
    font-size: 2em;
    font-weight: 400;
    color: var(--primary-color);
}

.contact_instructions {

    /* layout */
    text-align: center;

    /* padding */
    padding: 4px;

    /* font */
    font-family: Be Vietnam Pro;
    font-size: 0.875em;
    font-weight: 300;
    color: var(--primary-color);
}

.email {

    /* padding */
    padding: 4px;

    /* font */
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit
}

.email:focus-visible {


    /* background */
    background-color: var(--on-primary-color);

    /* font */
    color: var(--primary-color);

    /* border */
    border-radius: 4px;

}